<template>
  <div class="dimension-drawer">
    <el-drawer
      :visible.sync="showDrawer"
      direction="rtl"
      size="40%"
      :close-on-press-escape="false"
      :show-close="false"
      :wrapperClosable="false"
      :withHeader="false"
    >
      <div class="dime-drawer-box">
        <div class="dime-list">
          <div
            class="dime-item"
            v-for="(dimeItem, dimeIndex) in dimensionList"
            :key="dimeIndex"
          >
            <div class="dime-name">
              <span>{{ dimeItem.DimeName }}</span>
              <span v-show="dimeItem.AutoEnable" class="auto">自</span>
            </div>
            <div class="kind-box">
              <!-- <radio
                v-for="(kindItem, kindIndex) in dimeItem.KindList"
                :key="kindIndex"
                :value="kindItem.KindGuid"
                :checked="kindItem.Checked ? true : false"
                @click="onChangeRadio(dimeIndex, kindIndex)"
              >
                <div
                  class="sign-text"
                  :style="'background:' + kindItem.SignColor"
                >
                  {{ kindItem.SignText }}
                </div>
                <div class="kind-name">{{ kindItem.KindName }}</div>
              </radio> -->

              <el-checkbox
                :value="kindItem.Checked"
                :checked="kindItem.Checked"
                v-for="(kindItem, kindIndex) in dimeItem.KindList"
                :key="kindIndex"
                :true-label="1"
                :false-label="0"
                @change="onChangeRadio(dimeIndex, kindIndex)"
              >
                <div
                  class="sign-text"
                  :style="'background:' + kindItem.SignColor"
                >
                  {{ kindItem.SignText }}
                </div>
                <div class="kind-name">{{ kindItem.KindName }}</div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <div class="btn-box">
          <el-button class="close-btn" type="" @click="onCloseDrawer"
            >关 闭</el-button
          >
          <!-- <div class="reset-btn">重置</div> -->
          <el-button class="save-btn" type="primary" @click="onSubmitDimension"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Member from "@/api/member.js";

export default {
  props: {
    kindGuids: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dimensionList: [], // 会员维度列表
      showDrawer: false,
    };
  },

  mounted() {},

  methods: {
    // 获取会员维度列表
    async getDimensionSelector() {
      try {
        let { data } = await Member.getDimensionSelector();

        if (this.kindGuids.length > 0) {
          data.DimensionList.forEach((dimeItem) => {
            dimeItem.KindList.forEach((kindItem) => {
              if (this.kindGuids.includes(kindItem.KindGuid)) {
                kindItem.Checked = 1;
              }
            });
          });
        }

        this.dimensionList = data.DimensionList;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存会员维度
    onSubmitDimension() {
      let { dimensionList } = this;

      let kindGuids = [],
        signList = [];
      dimensionList.forEach((dimeItem) => {
        for (let i = 0; i < dimeItem.KindList.length; i++) {
          if (dimeItem.KindList[i].Checked) {
            kindGuids.push(dimeItem.KindList[i].KindGuid);
            signList.push(dimeItem.KindList[i]);
            break;
          }
        }
      });

      this.$nextTick(() => {
        this.$emit("select", {
          signList,
          kindGuids,
        });
        this.onCloseDrawer();
      });
    },

    // 单选框改变事件
    onChangeRadio(dimeIndex, kindIndex) {
      let { dimensionList } = this;
      console.log(dimeIndex, kindIndex);
      dimensionList[dimeIndex].KindList.forEach((item, index) => {
        if (index === kindIndex) {
          item.Checked = item.Checked ? 0 : 1;
        } else {
          item.Checked = 0;
        }
      });
      console.log(dimensionList);
      this.dimensionList = dimensionList;
    },

    // 打开对话框
    onShowDrawer() {
      this.getDimensionSelector();
      this.showDrawer = true;
    },

    // 关闭对话框
    onCloseDrawer() {
      this.showDrawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dimension-drawer {
  .tags-drawer-box,
  .dime-drawer-box {
    height: 100vh;

    .tags-list,
    .dime-list {
      .flex-col;
      align-items: unset;
      width: 100%;
      height: calc(100% - 60px);
      padding: 10px;
      box-sizing: border-box;
      overflow-y: auto;

      .kind-item,
      .dime-item {
        .flex-col;
        align-items: unset;
        margin-bottom: 10px;

        .kind-name,
        .dime-name {
          padding: 0 3px;
          margin-bottom: 3px;
          font-size: 18px;
          // color: #aaa;

          .auto {
            font-size: 14px;
            color: white;
            margin: 0 5px;
            padding: 2px 3px;
            border-radius: 4px;
            background-color: #f1bc3e;
          }
        }

        .tag-box,
        .kind-box {
          .flex-row;
          flex-wrap: wrap;

          .tag-item,
          .el-checkbox {
            margin: 5px;
            box-sizing: border-box;
          }

          // .tag-item {
          //   padding: 0.2rem 0.8rem;
          //   color: #888;
          //   background-color: white;
          //   border-radius: 0.8rem;
          //   border: 0.01rem solid #ccc;
          // }

          ::v-deep .el-checkbox {
            .flex-row;

            .el-checkbox__inner {
              transform: scale(1.5);
            }

            .el-checkbox__label {
              .flex-row;
              margin: 0;
            }

            .sign-text {
              .flex-row;
              justify-content: center;
              height: 20px;
              width: 20px;
              font-size: 14px;
              color: white;
              border-radius: 50%;
            }

            .kind-name {
              margin: 0;
              font-size: 16px;
            }
          }

          .active {
            // color: white;
            // background-color: $themeColor;
            // border-color: $themeColor;
          }
        }
      }
    }

    .btn-box {
      .flex-row;
      justify-content: center;
      align-items: unset;
      // height: 80px;
      padding: 10px 10px;
      box-sizing: border-box;

      .el-button {
        flex: 1;
      }
    }
  }
}
</style>

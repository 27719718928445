<template>
	<div class="record">
		<el-table
			:data="tableData"
			border
			size="mini"
			style="width: 100%">
			<el-table-column
				prop="BillNo"
				label="水单号">
			</el-table-column>
			<el-table-column
				prop="BillType"
				label="账单类型">
			</el-table-column>
			<el-table-column
				prop="BillAmount"
				label="账单金额">
			</el-table-column>
			<el-table-column
				prop="Detail"
				label="消费项目">
			</el-table-column>
			<el-table-column
				prop="StoresName"
				label="操作门店">
			</el-table-column>
			<el-table-column
				prop="BillNote"
				label="账单备注">
			</el-table-column>
			<el-table-column
				prop="SoftwareDate"
				label="记账日期">
				<template slot-scope="scope">
					{{ util.dateFormat('YYYY-mm-dd', new Date(scope.row.SoftwareDate * 1000)) }}
				</template>
			</el-table-column>
			<el-table-column
				prop="SoftwareTime"
				label="操作时间">
				<template slot-scope="scope">
					{{ util.dateFormat('YYYY-mm-dd', new Date(scope.row.SoftwareTime * 1000)) }}
				</template>
			</el-table-column>
			<el-table-column
				prop="RemoveTime"
				label="消单时间">
				<template slot-scope="scope">
					{{ scope.row.RemoveTime !== 0 ? util.dateFormat('YYYY-mm-dd', new Date(scope.row.RemoveTime * 1000)) : '' }}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import api from "../../../../../../api/member.js";
	export default {
		props: {
			
		},
		data(){
			return {
				submitData: { 
					member_guid: '', 
				},
				typeList: [],
				tableData: [],
				isAfreshLoadData: true,
				loading: false
			}
		},
		
	  methods: {
			initComponentData(event){
				this.submitData.member_guid = event.MemberGuid;
				if(this.isAfreshLoadData){
					this.isAfreshLoadData = false;
					this.getExpeKeepRecords();
				}
			},
			
			async getExpeKeepRecords(){
				this.loading = true;
				try{
					let { data } = await api.getExpeKeepRecords(this.submitData);
					this.typeList = data.typeList;
					this.tableData = data.list;
				}catch(e){
					//TODO handle the exception
				}
				this.loading = false;
			},
			
			clearComponentData(){
				this.isAfreshLoadData = true;
				this.submitData = {
					member_guid: ''
				}
				this.tableData = [];
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.record{
		padding-bottom: 20px;
	}
</style>
<template>
  <div>
    <!--  -->
    <el-dialog
      title="高级查询"
      :visible.sync="dialog"
      top="3%"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        ref="ruleForm"
        label-width="80px"
        label-position="left"
      >
        <div class="auto">
          <!-- dialog-center -->
          <div class="dialog-center">
            <div class="tabs">
              <div
                v-for="(item, index) in tabsData"
                :key="index"
                :class="isAction == index ? 'action' : ''"
                @click="onTabs(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="tabs-chile" v-show="isAction == 0">
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="会员卡号" label-width="80px">
                      <el-input
                        v-model="formData.VipNo"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="会员姓名" label-width="80px">
                      <el-input
                        v-model="formData.MemberName"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="会员手机" label-width="80px">
                      <el-input
                        v-model="formData.MemberPhone"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="会员分类" label-width="80px">
                      <el-select v-model="formData.MemberKind" size="small">
                        <el-option
                          v-for="(item, index) in advancedQuery.kindList"
                          :key="'kind_' + item.KindGuid"
                          :label="item.KindName"
                          :value="item.KindGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="会员状态" label-width="80px">
                      <el-select v-model="formData.MemberState" size="small">
                        <el-option
                          v-for="(item, index) in advancedQuery.memberState"
                          :key="'state_' + item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="会员性别" label-width="80px">
                      <el-select
                        v-model="formData.MemberSex"
                        placeholder="请选择会员性别"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.memberSex"
                          :key="'sex_' + item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="会员跨店" label-width="80px">
                      <el-select
                        v-model="formData.StoresShare"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.storesShare"
                          :key="'share_' + index"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="跟进人员" label-width="80px">
                      <el-select
                        v-model="formData.MemberFollow"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.followEmpl"
                          :key="'follow_' + item.EmplGuid"
                          :label="item.EmplName"
                          :value="item.EmplGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="来源渠道" label-width="80px">
                      <el-select
                        v-model="formData.SourceWay"
                        placeholder="请选择来源渠道"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.sourceWay"
                          :key="'sourceWay' + item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="会员流失" label-width="80px">
                      <el-select
                        v-model="formData.LossLevel"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.lossType"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="会员标签" label-width="80px">
                      <el-select
                        v-model="formData.MemberTag"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) in advancedQuery.memberTag"
                          :key="'tag_' + item.TagGuid"
                          :label="item.TagName"
                          :value="item.TagGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="推荐人" label-width="80px">
                      <DFMember
                        ref=""
                        @change="onSelectMember"
                      ></DFMember>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="auto">
                <el-row :gutter="20">
                  <el-col>
                    <el-form-item label="会员维度" label-width="80px">
                      <div class="dimension-box">
                        <div>
                          <div
                            class="sign-text"
                            v-for="(item, index) in signList"
                            :key="index"
                            :style="'background:' + item.SignColor"
                          >
                            {{ item.SignText }}
                          </div>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="onShowDrawer"
                          >选择维度</el-button
                        >
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="会员生日" label-width="80px">
                      <el-date-picker
                        v-model="birthdayArray"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="small"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="年龄" label-width="80px" prop="date1">
                      <el-input v-model="formData.StartAge" size="small">
                        <template slot="append">岁</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="至" label-width="30px" prop="date2">
                      <el-input v-model="formData.EndAge" size="small">
                        <template slot="append">岁</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item
                      label="消费频率"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-input v-model="formData.StartExpeFreq" size="small">
                        <template slot="append">天/次</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="至" label-width="30px" prop="date2">
                      <el-input v-model="formData.EndExpeFreq" size="small">
                        <template slot="append">天/次</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item
                      label="上次消费"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-input
                        v-model="formData.StartLastExpeAway"
                        size="small"
                      >
                        <template slot="append">天</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="至" label-width="30px" prop="date2">
                      <el-input v-model="formData.EndLastExpeAway" size="small">
                        <template slot="append">天</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>

              <!--  -->
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="最后消费" label-width="80px">
                      <el-date-picker
                        v-model="expeDateArray"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="small"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="消费日期" label-width="80px">
                      <el-date-picker
                        v-model="totalExpeArray"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="small"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="消费金额" label-width="80px">
                      <div class="amount-box">
                        <el-input
                          v-model="formData.TotalExpeBeginAmount"
                          placeholder="开始时间"
                        ></el-input>
                        <span v-html="'  至  '"></span>
                        <el-input
                          v-model="formData.TotalExpeEndAmount"
                          placeholder="结束时间"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-form-item label="充值日期" label-width="80px">
                      <el-date-picker
                        v-model="totalKeepArray"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="small"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>

                  <el-col :span="10">
                    <el-form-item label="充值金额" label-width="80px">
                      <div class="amount-box">
                        <el-input
                          v-model="formData.TotalKeepBeginAmount"
                          placeholder="开始时间"
                        ></el-input>
                        <span v-html="'  至  '"></span>
                        <el-input
                          v-model="formData.TotalKeepEndAmount"
                          placeholder="结束时间"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info" size="small" @click="closeSeniorDialog"
              >取消</el-button
            >
            <el-button type="primary" @click="onSubmit" size="small"
              >确认</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <DimensionDrawer
      ref="dimensionDrawer"
      :kindGuids="kindGuids"
      @select="onSelectDimension"
    ></DimensionDrawer>
  </div>
</template>

<script>
import DFMember from "@/components/df-member/df-member.vue";
import DimensionDrawer from "./dimension-drawer.vue";

export default {
  components: { DFMember, DimensionDrawer },
  data() {
    return {
      dialog: false,
      isAction: 0,
      tabsData: ["查询信息"],
      formData: {},
      birthdayArray: [],
      expeDateArray: [],
      totalExpeArray: [],
      totalKeepArray: [],
      signList: [],
      kindGuids: [],
      advancedQuery: {},
    };
  },
  methods: {
    openSeniorDialog(event, advancedQuery) {
      this.formData = event;
      this.advancedQuery = advancedQuery;
      this.dialog = true;
    },

    onShowDrawer() {
      this.$refs.dimensionDrawer.onShowDrawer();
    },

    closeSeniorDialog() {
      this.dialog = false;
    },

    onTabs(index) {
      this.isAction = index;
    },

    onSelectDimension(event) {
      // console.log(event);
      this.signList = event.signList;
      this.kindGuids = event.kindGuids;
    },

    onSelectMember(event){
      this.formData.IntroduceGuid = event.MemberGuid
    },

    onSubmit() {
      let {
        formData,
        birthdayArray,
        expeDateArray,
        totalExpeArray,
        totalKeepArray,
      } = this;

      if (birthdayArray !== null && birthdayArray.length > 0) {
        formData.StartBirthday = birthdayArray[0];
        formData.EndBirthday = birthdayArray[1];
      } else {
        formData.StartBirthday = "";
        formData.EndBirthday = "";
      }

      if (expeDateArray !== null && expeDateArray.length > 0) {
        formData.StartLastExpeDate = expeDateArray[0];
        formData.EndLastExpeDate = expeDateArray[1];
      } else {
        formData.StartLastExpeDate = "";
        formData.EndLastExpeDate = "";
      }

      if (totalExpeArray !== null && totalExpeArray.length > 0) {
        formData.TotalExpeBeginDate = totalExpeArray[0];
        formData.TotalExpeEndDate = totalExpeArray[1];
      } else {
        formData.TotalExpeBeginDate = "";
        formData.TotalExpeEndDate = "";
      }

      if (totalKeepArray !== null && totalKeepArray.length > 0) {
        formData.TotalKeepBeginDate = totalKeepArray[0];
        formData.TotalKeepEndDate = totalKeepArray[1];
      } else {
        formData.TotalKeepBeginDate = "";
        formData.TotalKeepEndDate = "";
      }

      formData.StartAge = formData.StartAge ? formData.StartAge : 0;

      formData.EndAge = formData.EndAge ? formData.EndAge : 0;

      formData.StartExpeFreq = formData.StartExpeFreq
        ? formData.StartExpeFreq
        : 0;

      formData.EndExpeFreq = formData.EndExpeFreq ? formData.EndExpeFreq : 0;

      formData.StartLastExpeAway = formData.StartLastExpeAway
        ? formData.StartLastExpeAway
        : 0;

      formData.EndLastExpeAway = formData.EndLastExpeAway
        ? formData.EndLastExpeAway
        : 0;

      formData.TotalExpeBeginAmount = formData.TotalExpeBeginAmount
        ? formData.TotalExpeBeginAmount
        : 0;

      formData.TotalExpeEndAmount = formData.TotalExpeEndAmount
        ? formData.TotalExpeEndAmount
        : 0;

      formData.TotalKeepBeginAmount = formData.TotalKeepBeginAmount
        ? formData.TotalKeepBeginAmount
        : 0;

      formData.TotalKeepEndAmount = formData.TotalKeepEndAmount
        ? formData.TotalKeepEndAmount
        : 0;

      formData.DimeKindGuids = this.kindGuids;

      this.$emit("confirmSenior", formData);
      this.closeSeniorDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.auto {
  height: 700px;
  overflow-y: scroll;
}
.auto::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.auto::-webkit-scrollbar {
  width: 3px;
}
.auto::-webkit-scrollbar-thumb {
  background-color: #999999;
}
.content {
  border: 1px solid #dcdfe6;
}

.dialog-center {
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  .tabs {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-bottom: 20px;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 40px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      transition: all 0.5s;
    }
    .action {
      border-bottom: 2px solid #409eff;
    }
  }
  .tabs-chile {
    padding: 0 20px;
    .el-form-item {
      margin-bottom: 6px;
    }

    .el-date-editor {
      width: 100%;
    }

    .amount-box {
      display: flex;
      flex-direction: row;
      white-space: pre;

      el-input {
        width: auto;
        // flex: 1;
      }
    }
    .dimension-box {
      .flex-row;
      height: 40px;

      > div {
        .flex-row;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
        box-sizing: border-box;

        .sign-text {
          .flex-row;
          justify-content: center;
          height: 20px;
          width: 20px;
          margin: 0 3px;
          box-sizing: border-box;
          font-size: 14px;
          color: white;
          border-radius: 50%;
        }
      }
    }
  }
}

.dialog-bottom {
  margin-top: 20px;
  .el-form-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>

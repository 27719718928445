<template>
  <div class="add-member-dialog">
    <!--  -->
    <el-dialog
      title="新增会员"
      :visible.sync="dialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="ruleForm"
        :model="formData"
        label-width="80px"
        label-position="left"
        size="small"
        class="demo-ruleForm"
      >
        <div class="content">
          <div class="dialog-top">
            <div class="left">
              <DFUpload
                ref="DFUpload"
                @success="onSubmitForm"
                @error="onUploadError"
                @remove="onRemove"
              >
              </DFUpload>
            </div>
            <div class="right">
              <span>上传头像</span>
            </div>
          </div>
          <div class="dialog-center">
            <div class="tabs">
              <div
                v-for="(item, index) in tabsData"
                :key="index"
                :class="isAction == index ? 'action' : ''"
                @click="onTabs(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="tabs-chile" v-show="isAction == 0">
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="姓名" label-width="80px" prop="date1">
                      <el-input v-model="formData.MemberName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="昵称" label-width="80px" prop="date1">
                      <el-input v-model="formData.NickName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="手机号码"
                      label-width="70px"
                      prop="date2"
                    >
                      <el-input v-model="formData.MemberPhone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="性别" label-width="80px" prop="date1">
                      <el-select v-model="formData.MemberSex">
                        <el-option label="女" :value="0"> </el-option>
                        <el-option label="男" :value="1"> </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="生日" label-width="80px" prop="date1">
                      <el-date-picker
                        v-model="formData.Birthday"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="生日类型"
                      label-width="80px"
                      prop="date2"
                    >
                      <el-select v-model="formData.BirthdayType">
                        <el-option label="农历" :value="0"> </el-option>
                        <el-option label="新历" :value="1"> </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="职业" label-width="80px" prop="date1">
                      <el-input v-model="formData.Occupation"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="微信号"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-input v-model="formData.WechatNumber"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="会员档案"
                      label-width="80px"
                      prop="date2"
                    >
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item
                      label="推荐人"
                      label-width="80px"
                      prop="date1"
                    >
                      <DFMember
                        ref="DFMember"
                        @change="onSelectMember"
                      ></DFMember>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="注册日期"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-date-picker
                        v-model="formData.RegisterDate"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="会员标签"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-select
                        v-model="formData.MemberTag"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(item, index) in initComponentData.tagSelector"
                          :key="item.TagGuid"
                          :label="item.TagName"
                          :value="item.TagGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="7">
                    <el-form-item
                      label="跟踪员工1"
                      label-width="80px"
                      prop="date2"
                    >
                      <el-select v-model="formData.FollowGuid" clearable>
                        <el-option
                          v-for="(item,
                          index) in initComponentData.emplSelector"
                          :key="'F_1' + item.EmplGuid"
                          :label="item.EmplName"
                          :value="item.EmplGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item
                      label="跟踪员工2"
                      label-width="80px"
                      prop="date1"
                    >
                      <el-select v-model="formData.FollowGuid2" clearable>
                        <el-option
                          v-for="(item,
                          index) in initComponentData.emplSelector"
                          :key="'F_2' + item.EmplGuid"
                          :label="item.EmplName"
                          :value="item.EmplGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item
                      label="跟踪员工3"
                      label-width="80px"
                      prop="date2"
                    >
                      <el-select v-model="formData.FollowGuid3" clearable>
                        <el-option
                          v-for="(item,
                          index) in initComponentData.emplSelector"
                          :key="'F_3' + item.EmplGuid"
                          :label="item.EmplName"
                          :value="item.EmplGuid"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="通知设置">
                <el-checkbox
                  v-model="formData.SendExpeSms"
                  label="停止所有消费短信通知"
                  :true-label="1"
                  :false-label="0"
                >
                </el-checkbox>
                <el-checkbox
                  v-model="formData.SendExpeWechat"
                  label="停止所有消费微信通知"
                  :true-label="1"
                  :false-label="0"
                >
                </el-checkbox>
                <el-checkbox
                  v-model="formData.SendKeepSms"
                  label="停止所有充值短信通知"
                  :true-label="1"
                  :false-label="0"
                >
                </el-checkbox>
                <el-checkbox
                  v-model="formData.SendKeepWechat"
                  label="停止所有充值微信通知"
                  :true-label="1"
                  :false-label="0"
                >
                </el-checkbox>
              </el-form-item>

              <el-form-item label="其他">
                <el-checkbox
                  v-model="formData.DebtMark"
                  label="禁止欠款"
                  :true-label="1"
                  :false-label="0"
                >
                </el-checkbox>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="formData.MemberNote">
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info" @click="onCloseDialog">取消</el-button>
            <el-button
              type="primary"
              :disabled="submitDisabled"
              @click="onUploadPicture"
              >确认</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/member.js";
import { baseURL } from "@/utils/axios.js";
import DFMember from "@/components/df-member/df-member.vue";
export default {
  components: { DFMember },
  data() {
    return {
      dialog: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      formData: {
        MemberGuid: "",
        Birthday: "",
        RegisterDate: "",
        MemberName: "",
        MemberPhone: "",
        NickName: "",
        MemberSex: 0,
        BirthdayType: 0,
        Occupation: "",
        WechatNumber: "",
        SendExpeSms: 0,
        SendKeepSms: 0,
        SendExpeWechat: 0,
        SendKeepWechat: 0,
        DebtMark: 0,
        MemberNote: "",
        FollowGuid: "",
        FollowGuid2: "",
        FollowGuid3: "",
        MemberTag: [],
        IntroduceGuid: "",
        Picture: "",
      },
      rules: {},
      isAction: 0,
      tabsData: ["个人信息"],
      initComponentData: {},
      urlAction:
        baseURL +
        "/member/save_member_picture?acctoken=" +
        window.sessionStorage.getItem("token"),
      fileList: [],
      uploadSuccess: false,
      submitDisabled: false,
    };
  },

  mounted() {},

  methods: {
    async openAddMemberDialog(event) {
      try {
        let { data } = await api.initSaveMemberData({ subact: "add" });
        this.initComponentData = data;
        // console.log(data);
        this.dialog = true;
      } catch (e) {}
    },

    onUploadError(event) {
      console.log(event);
    },

    onRemove() {},

    handleRemove(file) {
      console.log(file);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleDownload(file) {
      console.log(file);
    },

    onTabs(index) {
      this.isAction = index;
    },

    async onUploadPicture() {
      let token = await window.sessionStorage.getItem("token");

      // this.$refs.submitButton.disabled = await true;

      this.$refs.DFUpload.submitUpload(this.uploadSuccess ? '' : this.urlAction);
    },

    // async uploadSuccess(response, file, fileList) {
    //   try {
    //     if (response.errcode !== 0) {
    //       this.$message.error(response.errmsg);
    //     } else {
    //       this.formData.Picture = response.data;
    //     }
    //     this.onSubmitForm();
    //   } catch (e) {}
    // },

    uploadRrror(err) {
      this.$message.error(err.errmsg);
    },

    async onSubmitForm(event) {
      this.submitDisabled = true;
      let imageUrl = (await event) ? event.data : "";
      if (event == false) {
        imageUrl = this.formData.Picture || ''
      }else{
        this.uploadSuccess = true
      }
      try {
        this.formData.Picture = await imageUrl;
        // console.log(this.formData);
        let { data, errcode, errmsg } = await api.saveMember(
          this.formData,
          "add"
        );
        if (errcode == 0) {
          await this.$message({
            message: "添加成功!",
            type: "success",
          });
          await this.onCloseDialog();
        } else {
          await this.$message.error(errmsg);
        }
      } catch (e) {
      } finally {
        this.submitDisabled = false;
      }
    },

    onSelectMember(event) {
      this.formData.IntroduceGuid = event.MemberGuid;
    },

    onCloseDialog() {
      this.$refs["ruleForm"].resetFields();
      this.dialog = false;
      this.formData = {
        MemberGuid: "",
        Birthday: "",
        RegisterDate: "",
        MemberName: "",
        MemberPhone: "",
        NickName: "",
        MemberSex: 0,
        BirthdayType: 0,
        Occupation: "",
        WechatNumber: "",
        SendExpeSms: 0,
        SendKeepSms: 0,
        SendExpeWechat: 0,
        SendKeepWechat: 0,
        DebtMark: 0,
        MemberNote: "",
        FollowGuid: "",
        FollowGuid2: "",
        FollowGuid3: "",
        MemberTag: [],
        IntroduceGuid: "",
        Picture: "",
      };
      this.fileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.add-member-dialog {
  .content {
    height: 700px;
    overflow-y: scroll;
  }

  .content::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  .content::-webkit-scrollbar {
    width: 3px;
  }

  .content::-webkit-scrollbar-thumb {
    background-color: #999999;
  }

  .dialog-top {
    display: flex;
    align-items: center;
    min-height: 200px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    padding: 20px 0;
    .left {
      max-width: 470px;
      margin: 0 20px;
    }
    .right {
      display: flex;
      align-items: center;
      i {
        font-size: 38px;
        margin-right: 20px;
      }
    }
  }

  .dialog-center {
    margin-top: 20px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    .tabs {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      border-bottom: 1px solid #dcdfe6;
      box-sizing: border-box;
      margin-bottom: 20px;
      > div {
        display: flex;
        align-items: center;
        height: 40px;
        margin-right: 40px;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        transition: all 0.5s;
      }
      .action {
        border-bottom: 2px solid #409eff;
      }
    }
    .tabs-chile {
      padding: 0 20px;
    }
  }

  .dialog-bottom {
    margin-top: 20px;
    .el-form-item {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  .el-date-editor {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}
</style>

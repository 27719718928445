<template>
  <div class="information">
    <div class="tabs-chile">
      <ul class="table">
        <li>
          <div>
            <span>{{ editData.AddupExpeMoney }}元</span>
            <span>累计消费</span>
          </div>
          <div>
            <span>{{ editData.AddupExpeCount }}</span>
            <span>消费次数</span>
          </div>
        </li>
        <li>
          <div>
            <span>{{ totalBalance.CumuKeepMoney }}元</span>
            <span>累计充值</span>
          </div>
          <div>
            <span>{{ totalBalance.CumuKeepCount }}天/次</span>
            <span>充值次数</span>
          </div>
        </li>
        <li>
          <div>
            <span>{{
              util.dateFormat(
                "YYYY-mm-dd",
                new Date(editData.LastExpeTime * 1000)
              )
            }}</span>
            <span>上次消费</span>
          </div>
          <div>
            <span>{{ editData.ExpeFrequency }}小时前</span>
            <span>消费频率</span>
          </div>
        </li>
      </ul>
      <el-form-item label-width="auto" required>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="姓名" label-width="80px">
              <el-input v-model="editData.MemberName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="昵称" label-width="80px">
              <el-input v-model="editData.NickName" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号码" label-width="80px">
              <el-input v-model="editData.MemberPhone" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label-width="auto" required>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="性别" label-width="80px">
              <el-select
                v-model="editData.MemberSex"
                placeholder="请选择"
                size="small"
              >
                <el-option label="女" :value="0"> </el-option>
                <el-option label="男" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生日" label-width="80px">
              <el-date-picker
                v-model="editData.Birthday"
                type="date"
                placeholder="选择日期"
                size="small"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生日类型" label-width="80px" prop="date2">
              <el-select v-model="editData.BirthdayType" size="small">
                <el-option label="农历" :value="0"> </el-option>
                <el-option label="新历" :value="1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label-width="auto" required>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="微信号" label-width="80px" prop="date2">
              <el-input v-model="editData.WechatNumber" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="推荐人" label-width="80px" prop="date1">
              <DFMember ref="" @change="onSelectMember"></DFMember>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="来源渠道" label-width="80px" prop="date1">
              <el-select
                v-model="editData.SourceWay"
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in clientSource"
                  :key="'cs' + item.Value"
                  :label="item.Name"
                  :value="item.Name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label-width="auto" required>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="跟踪员工1" label-width="80px" prop="date1">
              <el-select
                v-model="editData.FollowGuid"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in emplSelector"
                  :key="'F_1' + item.EmplGuid"
                  :label="item.EmplName"
                  :value="item.EmplGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="跟踪员工2" label-width="80px" prop="date2">
              <el-select
                v-model="editData.FollowGuid2"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in emplSelector"
                  :key="'F_3' + item.EmplGuid"
                  :label="item.EmplName"
                  :value="item.EmplGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="跟踪员工3" label-width="80px" prop="date2">
              <el-select
                v-model="editData.FollowGuid3"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in emplSelector"
                  :key="'F_3' + item.EmplGuid"
                  :label="item.EmplName"
                  :value="item.EmplGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="通知设置">
        <el-checkbox
          v-model="editData.SendExpeSms"
          label="停止所有消费短信通知"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
        <el-checkbox
          v-model="editData.SendExpeWechat"
          label="停止所有消费微信通知"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
        <el-checkbox
          v-model="editData.SendKeepSms"
          label="停止所有充值短信通知"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
        <el-checkbox
          v-model="editData.SendKeepWechat"
          label="停止所有充值微信通知"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
      </el-form-item>

      <el-form-item label-width="auto" required>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="消费周期" label-width="80px">
              <el-input
                v-model="editData.ExpeCycleDays"
                size="small"
                @change="onChangeExpeCycleDays"
              >
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col v-if="editData.MemberSex == 0" :span="8">
            <el-form-item label="生理期" label-width="80px">
              <el-select v-model="editData.MenstrualDay" size="small">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="其他">
        <el-checkbox
          v-model="editData.DebtMark"
          label="禁止欠款"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
        <el-checkbox
          v-model="editData.ForbidViewHistory"
          label="禁止查看消费历史"
          :true-label="1"
          :false-label="0"
        >
        </el-checkbox>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="editData.MemberNote" type="textarea"></el-input>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import api from "@/api/member.js";
import DFMember from "@/components/df-member/df-member.vue";
export default {
  components: { DFMember },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    emplSelector: {
      type: Array,
      default: () => [],
    },

    totalBalance: {
      type: Object,
      default: () => {
        return {};
      },
    },

    clientSource: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      options: [],
    };
  },

  mounted() {
    let arr = [];
    for (let i = 0; i < 32; i++) {
      if (i == 0) {
        arr.push({ text: "请选择", value: 0 });
      } else {
        arr.push({ text: `${i - 1}日`, value: i });
      }
    }
    this.options = arr;
  },

  methods: {
    // 提交保存数据
    async submitForm() {
      this.$emit("submitForm", this.editData);
    },

    // 消费周期改变事件
    onChangeExpeCycleDays(event) {
      if (String(event).indexOf(".") > -1) {
        this.$message.error("消费周期不能输入小数");
      }
      this.editData.ExpeCycleDays = parseInt(Number(event));
    },

    onSelectMember(event){
      this.editData.IntroduceGuid = event.MemberGuid
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  .table {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dcdfe6;
    margin-bottom: 20px;
    border-radius: 5px;
    > li {
      flex: 1;
      border-right: 1px solid #dcdfe6;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px 0;
      }
      > div:first-child {
        border-bottom: 1px solid #dcdfe6;
      }
    }
    > li:last-child {
      border-right: 0;
    }
  }

  .el-date-editor {
    width: 100%;
    .el-input {
      width: 100% !important;
    }
  }
}
</style>

<template>
  <div class="kind-dialog">
    <!-- start 批量设置会员分类对话框 -->
    <el-dialog
      title="批量设置会员分类"
      :visible.sync="showDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content">
        <el-form ref="form" label-width="80px">
          <el-form-item label="会员分类">
            <!-- start 会员分类下拉框 -->
            <el-select v-model="kindGuid" placeholder="请选择会员分类">
              <el-option
                v-for="item in kindList"
                :key="item.KindGuid"
                :label="item.KindName"
                :value="item.KindGuid"
              >
              </el-option>
            </el-select>
            <!-- end 会员分类下拉框 -->
          </el-form-item>
        </el-form>
      </div>

      <!-- 腿部 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- end 批量设置会员分类对话框-->
  </div>
</template>

<script>
import member from "@/api/member.js";
export default {
  data() {
    return {
      kindGuid: "", // 分类id
      selectedList: [], // 被选中会员列表
      kindList: [], // 分类列表
      showDialog: false, // 对话框显示
      submitDisabled: false, // 提交按钮禁用
    };
  },

  mounted() {},

  methods: {
    // 设置分类提交事件
    async onSubmitData() {
      this.submitDisabled = true;

      if (this.selectedList.length == 0) {
        this.submitDisabled = false;
        return this.$message.warning("请在表格勾选会员");
      }

      try {
        let MemberGuids = [];
        await this.selectedList.forEach((item) => {
          MemberGuids.push(item.MemberGuid);
        });

        let submitData = await {
          KindGuid: this.kindGuid,
          MemberGuids,
        };

        let { errcode } = await member.setKindBatch(submitData);
        if (errcode == 0) {
          this.$message.success("会员分类设置成功");
          this.resetPageData();
          this.onCloseDialog();
          this.$emit("success");
        }
      } finally {
        this.submitDisabled = false;
      }
    },

    // 重置对话框数据
    resetPageData() {
      this.kindGuid = "";
      this.selectedList = [];
      this.kindList = [];
    },

    // 打开对话框
    onShowDialog(kindList, selectedList) {
      this.kindList = kindList;
      this.selectedList = selectedList;
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
<template>
  <div class="record">
    <div>
      <el-row class="inte-row">
        <el-col :span="24">
          <el-select
            v-model="submitData.inte_type"
            placeholder="请选择积分类型"
            size="mini"
          >
            <el-option
              v-for="item in inteType"
              :key="'inte_' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
          <!--  -->
          <el-select
            v-model="submitData.record_type"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              v-for="item in recordType"
              :key="'record_' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
          <!--  -->
          <el-select
            v-model="submitData.vip_guid"
            placeholder="请选择会员卡"
            size="mini"
          >
            <el-option
              v-for="item in vipSelector"
              :key="'vip_' + item.VipGuid"
              :label="item.CardName"
              :value="item.VipGuid"
            >
            </el-option>
          </el-select>
          <!--  -->
          <el-button
            type="primary"
            size="mini"
            @click="getMemberInteRecordList"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="integralData"
      border
      size="mini"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column prop="InteTime" label="时间">
        <template slot-scope="scope">
          {{
            scope.row.InteTime !== 0
              ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.InteTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="Content" label="内容"> </el-table-column>
      <el-table-column prop="CardName" label="会员卡"> </el-table-column>
      <el-table-column prop="InteTypeText" label="积分类型"> </el-table-column>
      <el-table-column prop="Amount" label="积分变动"> </el-table-column>
      <el-table-column prop="OverPlusAmount" label="剩余积分">
      </el-table-column>
      <el-table-column prop="StoresName" label="店名"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import api from "../../../../../../api/member.js";
export default {
  props: {
    inteType: {
      type: Array,
      default: () => [],
    },

    recordType: {
      type: Array,
      default: () => [],
    },

    vipSelector: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      submitData: {
        record_type: 0,
        inte_type: 0,
        member_guid: "",
        vip_guid: "",
      },
      isAfreshLoadData: true,
      integralData: [],
      loading: false,
    };
  },

  methods: {
    initComponentData(member_guid) {
      this.submitData.member_guid = member_guid;
      if (this.isAfreshLoadData) {
        this.isAfreshLoadData = false;
        this.getMemberInteRecordList();
      }
    },

    async getMemberInteRecordList() {
      this.loading = true;
      try {
        let { data } = await api.getMemberInteRecordList(this.submitData);
        this.integralData = data.InteRecordList;
        // console.log(data);
      } catch (e) {}
      this.loading = false;
    },

    clearComponentData() {
      this.isAfreshLoadData = true;
      this.submitData = {
        record_type: 0,
        inte_type: 0,
        member_guid: "",
        vip_guid: "",
      };
      this.integralData = [];
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  padding-bottom: 20px;
  .inte-row {
    margin-bottom: 20px;
    .el-select {
      margin-right: 10px;
      width: 140px;
    }
  }
}
</style>
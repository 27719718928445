<template>
	<div class="record">
		<el-table
			:data="tableData"
			border
			size="mini"
			style="width: 100%">
			<el-table-column
				prop="CouponTypeName"
				label="类型">
			</el-table-column>
			<el-table-column
				prop="CouponName"
				label="名称">
			</el-table-column>
			<el-table-column
				prop="CouponMemberNo"
				label="券码">
			</el-table-column>
			<el-table-column
				prop="ValidWeek"
				label="可用时间">
			</el-table-column>
			<el-table-column
				prop="UseNote"
				label="使用说明">
			</el-table-column>
			<el-table-column
				prop="Preferential"
				label="优惠幅度">
				<template slot-scope="scope">
					{{ scope.row.CouponType == 1 ?  scope.row.Preferential + '元' : scope.row.CouponType == 2 ?  scope.row.Preferential + '折' : scope.row.Preferential + '次' }}
				</template>
			</el-table-column>
			<el-table-column
				prop="ValidDate"
				label="有效期">
				<template slot-scope="scope">
					{{ scope.row.ValidDate !== '无限期' ? scope.row.ValidDate + '天' : scope.row.ValidDate }}
				</template>
			</el-table-column>
			<el-table-column
				prop="AddTime"
				label="领劵时间">
				<template slot-scope="scope">
					{{ scope.row.AddTime !== 0 ? util.dateFormat('YYYY-mm-dd', new Date(scope.row.AddTime * 1000)) : '' }}
				</template>
			</el-table-column>
			<el-table-column
				prop="ExpireDate"
				label="到期时间">
				<template slot-scope="scope">
					{{ scope.row.ExpireDate !== 0 ? util.dateFormat('YYYY-mm-dd', new Date(scope.row.ExpireDate * 1000)) : '' }}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		props: {
			tableData: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				
			}
		}
		
	}
</script>

<style lang="less" scoped>
	.record{
		padding-bottom: 20px;
	}
</style>
<template>
	<div>
		<template v-for="(item, index) in vipData" >
			<div class="list" v-if="item.NatureNo == '0002'" :key="item.VipGuid">
				<div class="title">
					<span>{{ item.CardName }}</span>
					<span>（{{ item.VipNo }}）</span>
				</div>
				<div class="store">
					<div>{{ item.VipStoresName }}</div>
					<div>{{ item.NatureName }}</div>
				</div>
				<div class="money" v-for="(vipItem, index) in item.VipItemList" :key="vipItem.ItemGuid">
					<div>
						<div>项目名称</div>
						<div class="balance">{{ vipItem.ItemName }}</div>
					</div>
					<div>
						<div>消费额</div>
						<div class="balance">{{ vipItem.Consume }}</div>
					</div>
					<div>
						<div>剩余次数</div>
						<div class="balance">{{ vipItem.SurpCount }}</div>
					</div>
				</div>
				<div class="other">
					<div class="date">
						<div class="text">
							<div>有效日期</div>
							<div>{{ item.ValidDate == 0 ? '无限期' : util.dateFormat('YYYY-mm-dd', new Date(item.ValidDate * 1000)) }}</div>
						</div>
						<div class="text">
							<div>开卡时间</div>
							<div>{{ util.dateFormat('YYYY-mm-dd', new Date(item.OpenDate * 1000))  }}</div>
						</div>
					</div>
					<div class="node">
						<div>备注</div>
						<div>{{ item.VipNote }}</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'VipCard',
		props: {
			vipData: {
				type: Array,
				default: () => []
			}
			
		},
		data(){
			return {
				
			}
		},
		
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	.list{
		margin-bottom: 20px;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		overflow: hidden;
		.title{
			padding: 0 20px;
			height: 60px;
			line-height: 60px;
			color: #ffffff;
			font-size: 18px;
			background-image: linear-gradient(to right, #1b1f23 , #5a646b);
		}
		
		.store, .money{
			display: flex;
			padding: 20px;
			border-bottom: 1px solid #f1f1f1;
		}
		
		.store{
			>div:first-child{
				flex: 1;
			}
			
			.type, .state{
				margin-left: 20px;
			}
			
		}
		
		
		.money > div{
			width: 25%;
			.balance{
				margin-top: 5px;
				font-weight: bold;
			}
		}
		
		.other{
			padding: 20px;
			.date{
				display: flex;
				.text{
					width: 25%;
					>div:last-child{
						margin-top: 5px;
						font-weight: bold;
					}
				}
			}
			.node{
				padding-top: 20px;
				>div:last-child{
					margin-top: 5px;
					font-weight: bold;
				}
			}
		}
		
		.row{
			padding-bottom: 20px;
		}
	}
</style>